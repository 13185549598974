/* colors:
  black: #0d1317, white: #fdfffc, slate: #2b2d42, blue: #3f88c5, green: #169873 */

body {
  background-color:#0d1317;
}

h1, h2, h3 {
  font-family: 'Merriweather Sans', sans-serif;
  color: #fdfffc;
}

h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  color: #fdfffc;
}

a, li, p {
  font-family: 'Open Sans', sans-serif;
  color: #fdfffc;
}

.navbar-brand {
  display:inline-block;
  width:40%;
}

.navbar-brand img {
  background:#fdfffc;
  border-radius:10px;
  display:inline-block;
  margin-right:25px;
  width:15%;
}

.navbar-brand h2 {
  font-size:28px;
}

.nav-link, .dropdown-item, .navbar-brand h2 {
  color:#3f88c5!important;
  display:inline-block;
}

.banner {
  height:800px;
}

.vidbanner {
  height:fit-content;
  margin:25px auto;
}

.bantext {
  position:absolute;
  top:200px;
  left:100px;
  height:fit-content;
  background:rgba(43,45,66,0.75);
  border: #fdfffc 2px solid;
  border-radius:10px;
  padding:10px;
}

.bantext p {
  font-size:16px;
}

.darkhead {
  background-color:#0d1317!important;
}

.lighthead {
  background-color:#fdfffc!important;
}

.textwrapblue {
  background-color:#3f88c5;
  padding:25px;
}

.bgblue h1, .bgblue h2, .bgblue h3, .bgblue h5, .bgblue .h5, .bgblue h4, .bgblue p, .bgblue li {
  color:#fdfffc;
}

.textwrapslate {
  background-color:#2b2d42;
  padding:25px;
}

.bg-secondary .h5, .bg-secondary p {
  color:#2b2d42!important;
}

.bgslate h1, .bgslate h2, .bgslate .h5, .bgslate h3, .bgslate h5, .bgslate h4, .bgslate p, .bgslate li {
  color:#3f88c5;
}

.textwrapwhite {
  background-color:#fdfffc;
  padding:25px;
}

.bgwhite h1, .bgwhite h2, .bgwhite .h5, .bgwhite h3, .bgwhite h5, .bgwhite h4, .bgwhite p, .bgwhite li {
  color:#2b2d42;
}

.textwrapgreen {
  background-color:#169873;
  padding:25px;
}

.bggreen h1, .bggreen h2, .bggreen h3, .bggreen .h5, .bggreen h5, .bggreen h4, .bggreen p, .bggreen li {
  color:#0d1317;
}

.wideform .form-label {
  color:#3f88c5;
}

.wideform {
  background-color:#2b2d42;
  margin:15px auto;
  border:#fdfffc 2px solid;
  border-radius:10px;
  padding:20px;
}

.wideform .col-12 {
  margin-top:10px;
}

.tallform .col-3 .container {
  width:90%;
  background-color:#169873;
  border-radius:5px;
  padding:10px;
}

.tallform .col-12 {
  margin-top:10px;
}

.textminform .form-label {
  color:#fdfffc;
}

.textminform .col-12 {
  margin-top:10px;
}

.textminform .col-3 {
  border:#2b2d42 2px solid;
  margin:auto;
  padding:15px;
  border-radius:10px;
}